import React from 'react';

class Syntax extends React.Component {
    render() {
        return(
            <div className="flex w-full">
                syntax
            </div>
        )
    }
}
export default Syntax;
import React from 'react';
import SidebarParent from "./SidebarParent";

class SideMenu extends React.Component {
    render() {
        return(
                <ul className="menu p-4 w-80 min-h-full bg-base-200 text-base-content">
                    <li className="menu-title">Pasqyra e lëndës</li>

                    <div className="p-4">
                        <SidebarParent></SidebarParent>
                    </div>
                </ul>
        )
    }
}
export default SideMenu;
import React from "react";

class MainPage extends React.Component {
    render() {
        return(
            <div className="w-full min-h-screen-header">
                mainpage
                <div className="flex w-full">
                    {/* content */}
                    <div id="content" className="flex flex-col w-full p-4 md:p-12 max-h-screen-header overflow-y-auto">
                        <div className="mb-4 flex justify-between">
                            <div className="text-left w-full lg:max-w-2xl lg:mx-0 lg:w-2/3 w-full">
                                <div className="relative">
                                    <h1 className="text-2xl font-bold text-slate-700 sm:text-3xl relative z-10 mt-6">
                                        Gramatika e gjuhës shqipe.
                                    </h1>
                                </div>
                                <h2 className="mt-4 text-base font-normal leading-7 text-slate-500">
                                    Kjo faqe eshte nje nisme individuale qe synon të sjellë ne format digjital gramatiken e gjuhes shqipe.
                                    Permbajta bazohet ne dy librat e gramatikes se gjuhes shqipe, publikim i Akademise se shkencave te Shqiperise.
                                </h2>
                                <h2 className="mt-4 text-base font-normal leading-7 text-slate-500">
                                    Per te kerkuar nje koncept apo rregull, mjafton te klikoni ne kategorite e meposhtme, apo te shkoni ne faqen
                                    <a href='/content'>Permbajtja</a>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MainPage;
import React from 'react';
import {Outlet} from "react-router-dom";

function NjohuriTePergjithshmeIndex() {
    return (
        <div>
            <h1>NJOHURI TE PERDJITHSHME LEVEL 1</h1>

            <div>
                <Outlet />
            </div>
        </div>
    );
}
export default NjohuriTePergjithshmeIndex
import React from 'react';
import SidebarItem from "./SidebarItem";
import SidebarData from "./SidebarData";
import {Link, useLocation} from "react-router-dom";

function SidebarParent() {
    const location = useLocation();

    return (
        <ul>
            {SidebarData.map((parent) => (
                <li>
                    <Link to={parent.parentRoute}>
                        <SidebarItem
                            key={parent.parentName}
                            name={parent.parentName}
                            route={parent.parentRoute}
                            activeRoute={location.pathname}
                        />
                    </Link>

                    {parent.children && parent.children.length > 0 ? (
                        <ul>
                            {parent.children.map(child => (
                                <Link to={child.childRoute}>
                                    <SidebarItem
                                        key={child.childName}
                                        name={child.childName}
                                        route={child.childRoute}
                                        activeRoute={location.pathname}
                                    />
                                </Link>
                            ))}
                        </ul>
                    ) : (
                        console.log('No children')
                    )}
                </li>
            ))}
        </ul>
    );
}

export default SidebarParent;
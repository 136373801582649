import React from 'react';

function SidebarItem({ name, route, activeRoute}) {
    const isActive = route === activeRoute;

    return (
        <li>
            <a
               name={name}
               title={name}
               href={route}
               className={`menu ${isActive ? 'active' : ''}`}>
                <span>{name}</span>
            </a>
        </li>
    );
}

export default SidebarItem;
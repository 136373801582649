const SidebarData = [
    {
        parentName: "Njohuri te përgjithshme",
        parentRoute: '/morfologjia/njohuriTePergjithshme',
        children: [
            {
                childName: "Objekti i morfologjisë",
                childRoute: '/morfologjia/njohuriTePergjithshme/objektiMorfologjise',
            },
            {
                childName: "Lidhjet e morfologjisë me sintaksën dhe fonetikën",
                childRoute: '/morfologjia/njohuriTePergjithshme/lidhjet'
            },
            {
                childName: "Kuptimet dhe format gramatikore",
                childRoute: '/morfologjia/njohuriTePergjithshme/kuptimetFormatGramatikore'
            },
            {
                childName: "Pjesët e ligjëratës",
                childRoute: '/morfologjia/njohuriTePergjithshme/pjesetELigjerates'
            },
            {
                childName: "Lokucionet",
                childRoute: '/morfologjia/njohuriTePergjithshme/lokucionet'
            },
        ]
    },
    {
        parentName: "Struktura morfologjike e fjales dhe tipat e fjaleformimit",
        parentRoute: '/morfologjia/strukturaMorfologjike',
        children: [
            {
                childName: "Fjala dhe morfema",
                childRoute: '/morfologjia/njohuriTePergjithshme/lokucionet'
            },
        ]
    },
    {
        parentName: "Emri",
        parentRoute: '/morfologjia/emri',
        children: [
            {
                childName: "Njohuri të përgjithshme",
                childRoute: '/morfologjia/njohuriTePergjithshme/lokucionet'
            },
        ]
    },
    {
        parentName: "Mbiemri",
        parentRoute: '/morfologjia/emri'
    },
];

export default SidebarData;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Donations from "./routes/Donations";
import Contact from "./routes/Contact";
import Content from "./routes/Content";
import Home from "./routes/Home";
import HeaderMenu from "./components/header-menu/HeaderMenu";
import Morfologjia from "./routes/morfologjia/Morfologjia";
import ObjektiMorfologjise from "./routes/morfologjia/njohuriTePergjithshme/objektiMorfologjise/ObjektiMorfologjise";
import Lidhjet from "./routes/morfologjia/njohuriTePergjithshme/lidhjet/Lidhjet";
import KuptimetFormatGramatikore from "./routes/morfologjia/njohuriTePergjithshme/kuptimetFormatGramatikore/KuptimetFormatGramatikore";
import PjesetELigjerates from "./routes/morfologjia/njohuriTePergjithshme/pjesetELigjerates/PjesetELigjerates";
import Lokucionet from "./routes/morfologjia/njohuriTePergjithshme/lokucionet/Lokucionet";
import NjohuriTePergjithshmeIndex from "./routes/morfologjia/njohuriTePergjithshme/NjohuriTePergjithshmeIndex";
import SideMenu from "./components/sidebar/SideMenu";
import Syntax from "./routes/sintaksa/Syntax";
import ComingSoonPage from './components/coming-soon/ComingSoonPage';
import EmriIndex from "./routes/morfologjia/emri/EmriIndex";

const root = ReactDOM.createRoot(document.getElementById('root'));
const showComingSoon = process.env.REACT_APP_COMING_SOON === 'true';

root.render(
  <React.StrictMode>
    { showComingSoon ? <ComingSoonPage /> : (
        <BrowserRouter>
          <Routes>
              <Route path="/" element={<HeaderMenu/>}>
                  <Route index element={<Home/>} />
                  <Route index element={<SideMenu/>} />
                  <Route path="/content" element={<Content/>} />
                  <Route path="/contact" element={<Contact/>} />
                  <Route path="/donations" element={<Donations/>} />
                  <Route path="/syntax" element={<Syntax/>} />
                  <Route path="/morfologjia" element={<Morfologjia/>}>
                      <Route path="/morfologjia/njohuriTePergjithshme" element={<NjohuriTePergjithshmeIndex/>}/>
                          <Route path="/morfologjia/njohuriTePergjithshme/objektiMorfologjise" element={<ObjektiMorfologjise/>} />
                          <Route path="/morfologjia/njohuriTePergjithshme/lidhjet" element={<Lidhjet/>} />
                          <Route path="/morfologjia/njohuriTePergjithshme/kuptimetFormatGramatikore" element={<KuptimetFormatGramatikore/>} />
                          <Route path="/morfologjia/njohuriTePergjithshme/pjesetELigjerates" element={<PjesetELigjerates/>} />
                          <Route path="/morfologjia/njohuriTePergjithshme/lokucionet" element={<Lokucionet/>} />
                      <Route path="emri" element={<EmriIndex/>} />
                      <Route path="/morfologjia/strukturaMorfologjike" element={<EmriIndex/>} />
                  </Route>
              </Route>
          </Routes>
      </BrowserRouter>
      )}
  </React.StrictMode>
);
